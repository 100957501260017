import "init/jiffy";
import "common/base";

/* ca */
import "components/jiffy/masthead/masthead";
import "components/jiffy/price-line-through/price-line-through";
import "components/jiffy/usa-redirect-modal/usa-redirect-modal";
import "components/jiffy/app-store-badge/app-store-badge";

/* application_head */
import "components/jiffy/promo-banner-list/promo-banner-list";
import "components/jiffy/promo-banner/promo-banner";
import "components/jiffy/button-box/button-box";
import "components/jiffy/text-box/text-box";
import "components/jiffy/check-box/check-box";
import "components/jiffy/input-box/input-box";
import "components/jiffy/radio-box/radio-box";
import "components/jiffy/flash-messages/flash-messages";

/* application */
import initAccessibility from "common/jiffy/accessibility";
import initModalWrapper from "components/jiffy/modal-wrapper/modal-wrapper";
import initProducts from "components/jiffy/products/products";
import initProductsBrands from "components/jiffy/products-brands/products-brands";
import initSiteJabber from "components/jiffy/site-jabber-carousel/site-jabber-carousel";
import initSocialLinks from "components/jiffy/social-icons/social-icons";
import initSoftnessScore from "components/compositions/softness_score/component";
import "components/jiffy/jiffy-pagination/jiffy-pagination";
import "components/jiffy/shipment-delivery/shipment-delivery";
import "components/jiffy/site-jabber/site-jabber";
import "components/spinner/component";
import "components/jiffy/notification-alert/notification-alert";
import "components/jiffy/stars/stars";
import initPLPTransfer from "components/jiffy/product-card-transfers/product-card-transfers";
import initRebrandAnnouncementModal from "components/rebrand/announcement_modal/component";

document.addEventListener("DOMContentLoaded", () => {
  initAccessibility();
  initModalWrapper();
  initSiteJabber();
  initSoftnessScore();
  initRebrandAnnouncementModal();

  if (document.querySelector(".js-products")) initProducts();
  if (document.querySelector(".js-products-brands")) initProductsBrands();
  if (document.querySelector(".js-social-link")) initSocialLinks();
  if (document.querySelector(".js-product-card-transfers")) initPLPTransfer();
});
