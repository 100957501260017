/* eslint-disable max-lines-per-function */
import { api } from "jiffy-api";
import { sendDataLayer } from "jiffy-analytics";
import "./usa-redirect-modal.scss";

const sendGaOnce = (() => {
  let a = 0;
  return (...params) => {
    if (a > 0) return;

    a += 1;

    sendDataLayer(...params);
  };
})();

const initRedirectModalUSA = (countryCodeTo, countryCodeFrom) => {
  const redirectModal = document.querySelector(`#${countryCodeTo}-redirect-modal`);
  const gaLabel = `${countryCodeTo}:${countryCodeFrom}`;

  if (redirectModal) {
    if (redirectModal.dataset.showModal === "true") {
      $(redirectModal).modal("show");
    }

    $(redirectModal).on("hide.bs.modal", () => {
      api.storePreference.post();

      sendGaOnce({
        genericEvent: true,
        eventCategory: "i18n:redirectPopup",
        event: "close",
        eventLabel: `${gaLabel}:clickedOutside`
      });
    });
  }

  $(".redirect-modal__btn-close").click(() => {
    sendGaOnce({
      genericEvent: true,
      eventCategory: "i18n:redirectPopup",
      event: "close",
      eventLabel: `${gaLabel}:clickedClose`
    });
  });

  $(".redirect-modal__button").click(async $event => {
    $event.preventDefault();
    await api.storePreference.post();

    sendGaOnce(
      {
        genericEvent: true,
        eventCategory: "i18n:redirectPopup",
        event: "redirect",
        eventLabel: gaLabel
      },
      () => {
        window.location.href = $event.target.parentElement.href;
      }
    );
  });
};

window.initRedirectModalUSA = initRedirectModalUSA;
